<template>
  <v-container class="ps-6">
    <h1>Datenschutzerklärung</h1>
    <h2>Vorwort</h2>
    <p>
      Wir, WeddingFlow (nachfolgend: "das Unternehmen", "wir" oder "uns") nehmen den Schutz Ihrer personenbezogenen
      Daten ernst und möchten Sie an dieser Stelle über den Datenschutz in unserem Unternehmen informieren.<br><br>

      Uns sind im Rahmen unserer datenschutzrechtlichen Verantwortlichkeit durch das Inkrafttreten der
      EU-Datenschutz-Grundverordnung (Verordnung (EU) 2016/679; nachfolgend: "DSGVO") zusätzliche Pflichten auferlegt
      worden, um den Schutz personenbezogener Daten der von einer Verarbeitung betroffenen Person (wir sprechen Sie als
      betroffene Person nachfolgend auch mit "Kunde", "Nutzer", "Sie", "Ihnen" oder "Betroffener" an)
      sicherzustellen.<br><br>

      Soweit wir entweder alleine oder gemeinsam mit anderen über die Zwecke und Mittel der Datenverarbeitung
      entscheiden, umfasst dies vor allem die Pflicht, Sie transparent über Art, Umfang, Zweck, Dauer und
      Rechtsgrundlage der Verarbeitung zu informieren (vgl. Art. 13 und 14 DSGVO). Mit dieser Erklärung (nachfolgend:
      "Datenschutzhinweise") informieren wir Sie darüber, in welcher Weise Ihre personenbezogenen Daten von uns
      verarbeitet werden.<br><br>

      Unsere Datenschutzhinweise sind modular aufgebaut. Sie bestehen aus einem allgemeinen Teil für jegliche
      Verarbeitung personenbezogener Daten und Verarbeitungssituationen, die bei jedem Aufruf einer Webseite zum Tragen
      kommen (Teil A. Allgemeines) und einem besonderen Teil, dessen Inhalt sich jeweils nur auf die dort angegebene
      Verarbeitungssituation mit Bezeichnung des jeweiligen Angebots oder Produkts bezieht, insbesondere den hier näher
      ausgestalteten Besuch von Webseiten (Teil B. Besuch von Webseiten). Teil B ist relevant, wenn Sie unser deutsches
      Internetangebot inklusive der Auftritte in den sozialen Medien nutzen.

    </p>
    <h2>A. Allgemeines</h2>
    <ol>
      <li>
        <h3>Begriffsbestimmungen</h3>
        <p>
          Nach dem Vorbild des Art. 4 DSGVO liegen dieser Datenschutzhinweise folgende Begriffsbestimmungen zugrunde:
        </p>
        <ul>
          <li>
            "Personenbezogene Daten" (Art. 4 Nr. 1 DSGVO) sind alle Informationen, die sich auf eine identifizierte oder
            identifizierbare natürliche Person ("Betroffener") beziehen. Identifizierbar ist eine Person, wenn sie
            direkt
            oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, einer Kennnummer, einer
            Online-Kennung, Standortdaten oder mithilfe von Informationen zu ihren physischen, physiologischen,
            genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identitätsmerkmalen identifiziert
            werden
            kann. Die Identifizierbarkeit kann auch mittels einer Verknüpfung von derartigen Informationen oder anderem
            Zusatzwissen gegeben sein. Auf das Zustandekommen, die Form oder die Verkörperung der Informationen kommt es
            nicht an (auch Fotos, Video- oder Tonaufnahmen können personenbezogene Daten enthalten).
          </li>
          <li>
            "Verarbeiten" (Art. 4 Nr. 2 DSGVO) ist jeder Vorgang, bei dem mit personenbezogenen Daten umgegangen wird,
            gleich ob mit oder ohne Hilfe automatisierter (d.h. technikgestützter) Verfahren. Dies umfasst insbesondere
            das Erheben (d.h. die Beschaffung), das Erfassen, die Organisation, das Ordnen, die Speicherung, die
            Anpassung
            oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, die
            Verbreitung oder sonstige Bereitstellung, den Abgleich, die Verknüpfung, die Einschränkung, das Löschen oder
            die Vernichtung von personenbezogenen Daten sowie die Änderung einer Ziel- oder Zweckbestimmung, die einer
            Datenverarbeitung ursprünglich zugrunde gelegt wurde.
          </li>
          <li>
            "Verantwortlicher" (Art. 4 Nr. 7 DSGVO) ist die natürliche oder juristische Person, Behörde, Einrichtung
            oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von
            personenbezogenen Daten entscheidet.
          </li>
          <li>
            "Dritter" (Art. 4 Nr. 10 DSGVO) ist jede natürliche oder juristische Person, Behörde, Einrichtung oder
            andere Stelle außer dem Betroffenen, dem Verantwortlichen, dem Auftragsverarbeiter und den Personen, die
            unter
            der unmittelbaren Verantwortung des Verantwortlichen oder Auftragsverarbeiters befugt sind, die
            personenbezogenen Daten zu verarbeiten; dazu gehören auch andere konzernangehörige juristische Personen.
          </li>
          <li>
            "Auftragsverarbeiter" (Art. 4 Nr. 8 DSGVO) ist eine natürliche oder juristische Person, Behörde, Einrichtung
            oder andere Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen, insbesondere gemäß dessen
            Weisungen, verarbeitet (z.B. IT-Dienstleister). Im datenschutzrechtlichen Sinne ist ein Auftragsverarbeiter
            insbesondere kein Dritter.
          </li>
          <li>
            "Einwilligung" (Art. 4 Nr. 11 DSGVO) der betroffenen Person bezeichnet jede freiwillig für den bestimmten
            Fall, in informierter
            Weise und unmissverständlich abgegebene Willensbekundung in Form einer Erklärung oder einer sonstigen
            eindeutigen bestätigenden Handlung, mit der die betroffene Person zu verstehen gibt, dass sie mit der
            Verarbeitung der sie betreffenden personenbezogenen Daten einverstanden ist.
          </li>
        </ul>

      </li>
      <li>
        <h3>Name und Anschrift des für die Verarbeitung Verantwortlichen</h3>
        <p>
          Die für die Verarbeitung Ihrer personenbezogenen Daten verantwortliche Stelle im Sinne des Art. 4 Nr. 7 DSGVO
          sind wir:<br><br>
          WeddingFlow<br>
          Benedikt Binzer<br>
          Hans-Riggenbach-Straße 8<br>
          65795 Hattersheim<br>
          Telefon: +49 1573 9456571<br>
          E-Mail: benedikt@wedding-flow.de<br><br>
          Weitere Angaben zu unserem Unternehmen entnehmen Sie bitte den Impressumsangaben auf unserer Internetseite
          <a href="https://wedding-flow.de/#/impressum">https://wedding-flow.de/#/impressum.</a>
        </p>
      </li>
      <li>
        <h3>Rechtsgrundlagen der Datenverarbeitung</h3>
        <p>
          Von Gesetzes wegen ist im Grundsatz jede Verarbeitung personenbezogener Daten verboten und nur dann erlaubt,
          wenn die Datenverarbeitung unter einen der folgenden Rechtfertigungstatbestände fällt:
        </p>

        <ul>
          <li>
            Art. 6 Abs. 1 S. 1 lit. a DSGVO ("Einwilligung"): Wenn der Betroffene freiwillig, in informierter Weise und
            unmissverständlich durch eine Erklärung oder eine sonstige eindeutige bestätigende Handlung zu verstehen
            gegeben hat, dass er mit der Verarbeitung der ihn betreffenden personenbezogenen Daten für einen oder
            mehrere
            bestimmte
            Zwecke einverstanden ist;
          </li>
          <li>
            Art. 6 Abs. 1 S. 1 lit. b DSGVO: Wenn die Verarbeitung zur Erfüllung eines Vertrags, dessen Vertragspartei
            der Betroffene ist, oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist, die auf die Anfrage
            des
            Betroffenen erfolgen;
          </li>
          <li>
            Art. 6 Abs. 1 S. 1 lit. c DSGVO: Wenn die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung
            erforderlich ist, der der Verantwortliche unterliegt (z.B. eine gesetzliche Aufbewahrungspflicht);
          </li>
          <li>
            Art. 6 Abs. 1 S. 1 lit. d DSGVO: Wenn die Verarbeitung erforderlich ist, um lebenswichtige Interessen des
            Betroffenen oder einer anderen natürlichen Person zu schützen;
          </li>
          <li>
            Art. 6 Abs. 1 S. 1 lit. e DSGVO: Wenn die Verarbeitung für die
            Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung
            öffentlicher
            Gewalt erfolgt, die dem Verantwortlichen übertragen wurde oder
          </li>
          <li>
            Art. 6 Abs. 1 S. 1 lit. f DSGVO ("Berechtigte Interessen"): Wenn die Verarbeitung zur Wahrung berechtigter
            (insbesondere rechtlicher oder wirtschaftlicher) Interessen des
            Verantwortlichen oder eines Dritten erforderlich ist, sofern nicht die gegenläufigen Interessen oder Rechte
            des Betroffenen überwiegen (insbesondere dann, wenn es sich dabei um einen Minderjährigen handelt).
            Für die von uns vorgenommenen Verarbeitungsvorgänge geben wir im Folgenden jeweils die anwendbare
            Rechtsgrundlage an. Eine Verarbeitung kann auch auf mehreren Rechtsgrundlagen beruhen.
          </li>
        </ul>
      </li>
      <li>
        <h3>Datenlöschung und Speicherdauer</h3>
        <p>
          Für die von uns vorgenommenen Verarbeitungsvorgänge geben wir im Folgenden jeweils an, wie lange die Daten bei
          uns gespeichert und wann sie gelöscht oder gesperrt werden. Soweit nachfolgend keine ausdrückliche
          Speicherdauer angegeben wird, werden Ihre personenbezogenen Daten gelöscht oder gesperrt, sobald der Zweck
          oder die Rechtsgrundlage für die Speicherung entfällt. Eine Speicherung Ihrer Daten erfolgt grundsätzlich nur
          auf unseren Servern in Deutschland, vorbehaltlich einer ggf. erfolgenden Weitergabe nach den Regelungen in
          A.(6) und A.(7).Eine Speicherung kann jedoch über die angegebene Zeit hinaus im Falle einer (drohenden)
          Rechtsstreitigkeit mit Ihnen oder eines sonstigen rechtlichen Verfahrens erfolgen oder wenn die Speicherung
          durch gesetzliche Vorschriften, denen wir als Verantwortlicher unterliegen (zB § 257 HGB, § 147 AO),
          vorgesehen ist. Wenn die durch die gesetzlichen Vorschriften vorgeschriebene Speicherfrist abläuft, erfolgt
          eine Sperrung oder Löschung der personenbezogenen Daten, es sei denn, dass eine weitere Speicherung durch uns
          erforderlich ist und dafür eine Rechtsgrundlage besteht.
        </p>
      </li>
      <li>
        <h3>Datensicherheit</h3>
        <p>
          Wir bedienen uns geeigneter technischer und organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen
          zufällige oder vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder gegen den
          unbefugten Zugriff Dritter zu schützen (z.B. TSL-Verschlüsselung für unsere Webseite) unter Berücksichtigung
          des Stands der Technik, der Implementierungskosten und der Natur, des Umfangs, des Kontextes und des Zwecks
          der Verarbeitung sowie der bestehenden Risiken einer Datenpanne (inklusive von deren Wahrscheinlichkeit und
          Auswirkungen) für den Betroffenen. Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen
          Entwicklung fortlaufend verbessert. Nähere Informationen hierzu erteilen wir Ihnen auf Anfrage gerne.
        </p>
      </li>
      <li>
        <h3>Zusammenarbeit mit Auftragsverarbeitern</h3>
        <p>
          Wenn wir zur Abwicklung unseres Geschäftsverkehrs externe Dienstleister (z.B. für die Bereiche IT, Logistik,
          Telekommunikation, Vertrieb und Marketing) einsetzen, dann werden diese nur nach unserer Weisung tätig und
          werden i.S.v. Art. 28 DSGVO vertraglich dazu verpflichtet, die datenschutzrechtlichen Bestimmungen
          einzuhalten.
        </p>
      </li>
      <li>
        <h3>Voraussetzungen der Weitergabe von personenbezogenen Daten in Drittländer</h3>
        <p>
          Im Rahmen unserer Geschäftsbeziehungen können Ihre personenbezogenen Daten an Drittgesellschaften
          weitergegeben oder offengelegt werden. Diese können sich auch außerhalb des Europäischen Wirtschaftsraums
          (EWR), also in Drittländern, befinden. Eine derartige Verarbeitung erfolgt ausschließlich zur Erfüllung der
          vertraglichen und geschäftlichen Verpflichtungen und zur Pflege Ihrer Geschäftsbeziehung zu uns. Über die
          jeweiligen Einzelheiten der Weitergabe unterrichten wir Sie nachfolgend an den dafür relevanten Stellen.
          Einigen Drittländern bescheinigt die Europäische Kommission durch sog. Angemessenheitsbeschlüsse einen
          Datenschutz, der dem EWR-Standard vergleichbar ist (eine Liste dieser Länder sowie eine Kopie der
          Angemessenheitsbeschlüsse erhalten Sie hier:
          http://ec.europa.eu/justice/data-protection/internationaltransfers/adequacy/index_en.html). In anderen
          Drittländern, in die ggf. personenbezogene Daten übertragen werden, herrscht aber unter Umständen wegen
          fehlender gesetzlicher Bestimmungen kein durchgängig hohes Datenschutzniveau. Soweit dies der Fall ist, achten
          wir darauf, dass der Datenschutz ausreichend gewährleistet ist. Möglich ist dies über bindende
          Unternehmensvorschriften, Standard-Vertragsklauseln der Europäischen Kommission zum Schutz personenbezogener
          Daten, Zertifikate oder anerkannte Verhaltenskodizes.

        </p>
      </li>
      <li>
        <h3>Keine automatisiere Entscheidungsfindung (einschließlich Profiling)</h3>
        <p>
          Wir haben nicht die Absicht, von Ihnen erhobene personenbezogene Daten für ein Verfahren zur automatisierten
          Entscheidungsfindung (einschließlich Profiling) zu verwenden.
        </p>
      </li>
      <li>
        <h3>Keine Verpflichtung zur Bereitstellung personenbezogener Daten</h3>
        <p>
          Wir machen den Abschluss von Verträgen mit uns nicht davon abhängig, dass Sie uns zuvor personenbezogene Daten
          bereitstellen. Für Sie als Kunde besteht grundsätzlich auch keine gesetzliche oder vertragliche Verpflichtung,
          uns Ihre personenbezogenen Daten zur Verfügung zu stellen; es kann jedoch sein, dass wir bestimmte Angebote
          nur eingeschränkt oder gar nicht erbringen können, wenn Sie die dafür erforderlichen Daten nicht
          bereitstellen. Sofern dies im Rahmen der nachfolgend vorgestellten, von uns angebotenen Produkte ausnahmsweise
          der Fall sein sollte, werden Sie gesondert darauf hingewiesen.
        </p>
      </li>
      <li>
        <h3>Gesetzliche Verpflichtung zur Übermittlung bestimmter Daten</h3>
        <p>
          Wir können unter Umständen einer besonderen gesetzlichen oder rechtlichen Verpflichtung unterliegen, die
          rechtmäßig verarbeiteten personenbezogenen Daten für Dritte, insbesondere öffentlichen Stellen,
          bereitzustellen (Art. 6 Abs. 1 S. 1 lit. c DSGVO).
        </p>
      </li>
      <li>
        <h3>Ihre Rechte</h3>
        <p>
          Ihre Rechte als Betroffener bezüglich Ihrer verarbeiteten personenbezogenen Daten können Sie uns gegenüber
          unter den eingangs unter A.(2) angegebenen Kontaktdaten jederzeit geltend machen. Sie haben als
          Betroffener das Recht:
        </p>
        <ul>
          <li>
            gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten Daten zu verlangen. Insbesondere können Sie
            Auskunft über die Verarbeitungszwecke, die Kategorie der Daten, die Kategorien von Empfängern, gegenüber
            denen
            Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf
            Berichtigung, Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines
            Beschwerderechts,
            die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen einer
            automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen Informationen zu
            deren
            Einzelheiten verlangen;
          </li>
          <li>
            gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder die Vervollständigung Ihrer bei uns
            gespeicherten Daten zu verlangen;
          </li>
          <li>
            gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten Daten zu verlangen, soweit nicht die
            Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer
            rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder
            Verteidigung von Rechtsansprüchen erforderlich ist;
          </li>
          <li>
            gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer Daten zu verlangen, soweit die Richtigkeit der
            Daten von Ihnen bestritten wird oder die Verarbeitung unrechtmäßig ist;
          </li>
          <li>
            gemäß Art. 20 DSGVO Ihre Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und
            maschinenlesbaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen
            ("Datenübertragbarkeit");
          </li>
          <li>
            gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung einzulegen, sofern die Verarbeitung aufgrund von Art.
            6 Abs. 1 S. 1 lit. e oder lit. f DSGVO erfolgt. Dies ist insbesondere der Fall, wenn die Verarbeitung nicht
            zur Erfüllung eines Vertrags mit Ihnen erforderlich ist. Sofern es sich nicht um einen Widerspruch gegen
            Direktwerbung handelt, bitten wir bei Ausübung eines solchen Widerspruchs um die Darlegung der Gründe,
            weshalb
            wir Ihre Daten nicht wie von uns durchgeführt verarbeiten sollen. Im Falle Ihres begründeten Widerspruchs
            prüfen wir die Sachlage und werden entweder die Datenverarbeitung einstellen bzw. anpassen oder Ihnen unsere
            zwingenden schutzwürdigen Gründe aufzeigen, aufgrund derer wir die Verarbeitung fortführen;
          </li>
          <li>
            gemäß Art. 7 Abs. 3 DSGVO Ihre einmal (auch vor der Geltung der DSGVO, d.h. vor dem 25.5.2018) erteilte
            Einwilligung – also Ihr freiwilliger, in informierter Weise und unmissverständlich durch eine Erklärung oder
            eine sonstige eindeutige bestätigende Handlung verständlich gemachter Willen, dass Sie mit der Verarbeitung
            der betreffenden personenbezogenen Daten für einen oder mehrere bestimmte Zwecke einverstanden sind –
            jederzeit uns gegenüber zu widerrufen, falls Sie eine solche erteilt haben. Dies hat zur Folge, dass wir die
            Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen dürfen und
          </li>
          <li>
            gemäß Art. 77 DSGVO sich bei einer Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer
            personenbezogenen Daten in unserem Unternehmen zu beschweren.
          </li>
        </ul>


      </li>
      <li>
        <h3>Änderungen der Datenschutzhinweise</h3>
        <p>
          Im Rahmen der Fortentwicklung des Datenschutzrechts sowie technologischer oder organisatorischer Veränderungen
          werden unsere Datenschutzhinweise regelmäßig auf Anpassungs- oder Ergänzungsbedarf hin überprüft.
        </p>
      </li>
    </ol>
    <h2>B. Besuch von Webseiten</h2>
    <ol>
      <li>
        <h3>Erläuterung der Funktion</h3>
        <p>
          Informationen zu unseren Unternehmen und den von uns angebotenen Leistungen erhalten Sie insbesondere unter
          https://wedding-flow.de samt den dazugehörigen Unterseiten (nachfolgend gemeinsam: "Webseiten"). Bei einem
          Besuch unserer Webseiten können personenbezogene Daten von Ihnen verarbeitet werden.
        </p>
      </li>
      <li>
        <h3>Verarbeitete personenbezogene Daten</h3>
        <p>
          Bei der informatorischen Nutzung der Webseiten werden die folgenden Kategorien personenbezogener Daten von uns
          erhoben, gespeichert und weiterverarbeitet:
          "Protokolldaten": Wenn Sie unsere Webseiten besuchen, wird auf unserem Webserver temporär und anonymisiert ein
          sogenannter Protokolldatensatz (sog. Server-Logfiles) gespeichert. Dieser besteht aus:
        </p>
        <ul>
          <li>
            der Seite, von der aus die Seite angefordert wurde (sog. Referrer-
            URL)
          </li>
          <li>
            dem Name und URL der angeforderten Seite
          </li>
          <li>
            dem Datum und der Uhrzeit des Aufrufs
          </li>
          <li>
            der Beschreibung des Typs, Sprache und Version des verwendeten
            Webbrowsers
          </li>
          <li>
            der IP-Adresse des anfragenden Rechners, die so verkürzt wird, dass ein Personenbezug nicht mehr herstellbar
            ist
          </li>
          <li>
            der übertragenen Datenmenge
          </li>
          <li>
            dem Betriebssystem
          </li>
          <li>
            der Meldung, ob der Aufruf erfolgreich war (Zugriffsstatus/Http-
            Statuscode)
          </li>
          <li>
            der GMT-Zeitzonendifferenz
          </li>
        </ul>
        <p>"Kontaktformulardaten": Bei Nutzung von Kontaktformularen werden die dadurch übermittelten Daten verarbeitet
          (z.B. Geschlecht, Name und Vorname, Anschrift, Firma, E-Mail-Adresse und der Zeitpunkt der Übermittlung).
          Neben der rein informatorischen Nutzung unserer Webseite bieten wir das Abonnement unseres Newsletters an, mit
          dem wir Sie über aktuelle Entwicklungen im Wirtschaftsrecht und Veranstaltungen informieren. Wenn Sie sich für
          unseren Newsletter anmelden, werden die folgenden "Newsletterdaten" von uns erhoben, gespeichert und
          weiterverarbeitet:</p>
        <ul>
          <li>
            die Seite, von der aus die Seite angefordert wurde (sog. Referrer-
            URL)
          </li>
          <li>
            das Datum und die Uhrzeit des Aufrufs
          </li>
          <li>
            die Beschreibung des Typs des verwendeten Webbrowsers
          </li>
          <li>
            die IP-Adresse des anfragenden Rechners, die so verkürzt wird, dass ein Personenbezug nicht mehr herstellbar
            ist
          </li>
          <li>
            die E-Mail-Adresse
          </li>
          <li>
            das Datum und die Uhrzeit der Anmeldung und Bestätigung
          </li>
        </ul>
        <p>Wir weisen Sie darauf hin, dass wir bei Versand des Newsletters Ihr Nutzerverhalten auswerten. Für diese
          Auswertung beinhalten die versendeten E-Mails sogenannte Web-Beacons bzw. Tracking-Pixel, die
          Ein-PixelBilddateien darstellen, die auf unserer Website gespeichert sind. Für die Auswertungen verknüpfen wir
          die vorstehend genannten Daten und die Web-Beacons mit Ihrer E-Mail-Adresse und einer individuellen ID. Auch
          im Newsletter enthaltene Links enthalten diese ID. Die Daten werden ausschließlich pseudonymisiert erhoben,
          dh. die IDs werden also nicht mit Ihren weiteren persönlichen Daten verknüpft, eine direkte
          Personenbeziehbarkeit wird ausgeschlossen.
        </p>
      </li>
      <li>
        <h3>Zweck und Rechtsgrundlage der Datenverarbeitung</h3>
        <p>
          Wir verarbeiten die vorstehend näher bezeichneten personenbezogenen Daten in Einklang mit den Vorschriften der
          DSGVO, den weiteren einschlägigen Datenschutzvorschriften und nur im erforderlichen Umfang. Soweit die
          Verarbeitung der personenbezogenen Daten auf Art. 6 Abs. 1 S. 1 lit. f DSGVO beruht, stellen die genannten
          Zwecke zugleich unsere berechtigten Interessen dar. Die Verarbeitung der Protokolldaten dient statistischen
          Zwecken und der Verbesserung der Qualität unserer Webseite, insbesondere der Stabilität und der Sicherheit der
          Verbindung (Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DSGVO). Die Verarbeitung von Kontaktformulardaten
          erfolgt zur Bearbeitung von Kundenanfragen (Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. b oder lit. f DSGVO).
          Die Verarbeitung der Newsletterdaten erfolgt zum Zweck der Zusendung des Newsletters. Im Rahmen der Anmeldung
          zu unserem Newsletter willigen Sie in die Verarbeitung Ihrer personenbezogenen Daten ein (Rechtsgrundlage ist
          Art. 6 Abs. 1 lit. a DSGVO). Für die Anmeldung zu unserem Newsletter verwenden wir das sog.
          Double-Opt-In-Verfahren. Das heißt, dass wir Ihnen nach Ihrer Anmeldung eine E-Mail an die angegebene
          E-Mail-Adresse senden, in welcher wir Sie um Bestätigung bitten, dass Sie den Versand des Newsletters
          wünschen. Zweck dieses Verfahrens ist, Ihre Anmeldung nachweisen und ggf. einen möglichen Missbrauch Ihrer
          persönlichen Daten aufklären zu können. Ihre Einwilligung in die Übersendung des Newsletters können Sie
          jederzeit widerrufen und den Newsletter abbestellen. Den Widerruf können Sie durch Klick auf den in jeder
          Newsletter-E-Mail bereitgestellten Link, per E-Mail an [E-Mailadresse des Unternehmens] oder durch eine
          Nachricht an die im Impressum angegebenen Kontaktdaten erklären.
        </p>
      </li>
      <li>
        <h3>Dauer der Datenverarbeitung</h3>
        <p>
          Ihre Daten werden nur so lange verarbeitet, wie dies für die Erreichung der oben genannten Verarbeitungszwecke
          erforderlich ist; hierfür gelten die im Rahmen der Verarbeitungszwecke angegebenen Rechtsgrundlagen
          entsprechend. Hinsichtlich der Nutzung und der Speicherdauer von Cookies beachten Sie bitte Punkt A.(5) sowie
          in den Cookie-Einstellungen.
          Von uns eingesetzte Dritte werden Ihre Daten auf deren System so lange speichern, wie es im Zusammenhang mit
          der Erbringung der Leistungen für uns entsprechend dem jeweiligen Auftrag erforderlich ist. Näheres zur
          Speicherdauer finden Sie im Übrigen unter A.(5) und sowie in den Cookie-Einstellungen.
        </p>
      </li>
      <li>
        <h3>Übermittlung personenbezogener Daten an Dritte; Rechtfertigungsgrundlage</h3>
        <p>
          Folgende Kategorien von Empfängern, bei denen es sich im Regelfall um Auftragsverarbeiter handelt (siehe dazu
          A.(7)), erhalten ggf. Zugriff auf Ihre personenbezogenen Daten:
        </p>
        <ul>
          <li>
            Dienstleister für den Betrieb unserer Webseite und die Verarbeitung der durch die Systeme gespeicherten oder
            übermittelten Daten (zB für Rechenzentrumsleistungen,
            Zahlungsabwicklungen, IT-Sicherheit). Rechtsgrundlage für die Weitergabe ist dann Art. 6 Abs. 1 S. 1 lit. b
            oder lit. f DSGVO, soweit es sich nicht um Auftragsverarbeiter handelt;
          </li>
          <li>
            Staatliche Stellen/Behörden, soweit dies zur Erfüllung einer gesetzlichen Verpflichtung erforderlich ist.
            Rechtsgrundlage für die Weitergabe ist dann Art. 6 Abs. 1 S. 1 lit. c DSGVO;
          </li>
          <li>
            Zur Durchführung unseres Geschäftsbetriebs eingesetzte
            Personen (zB Auditoren, Banken, Versicherungen, Rechtsberater, Aufsichtsbehörden, Beteiligte bei
            Unternehmenskäufen oder der Gründung von Gemeinschaftsunternehmen). Rechtsgrundlage für die Weitergabe ist
            dann Art. 6 Abs. 1 S. 1 lit. b oder lit. f DSGVO.
          </li>
          <li>
            Stripe ( Stripe Technology Europe Ltd): Stripe ist ein Zahlungsdienst von Stripe Technology Europe Ltd. /
            Verarbeitungsort: Irland.
          </li>
          <li>
            SendinBlue Email (SendinBlue SAS) / Verarbeitungsort: Frankreich.
          </li>
          <li>
            iubenda Consent Solution (iubenda srl)
          </li>
          <li>
            iubenda Cookie Solution (iubenda srl)
          </li>
          <li>
            Cookiebot by Usercentrics
          </li>
          <li>
            Google Fonts (lokal bereitgestellt)
          </li>
          <li>
            Google Analytics
          </li>
          <li>
            Google API (Google Maps, Geocoding)
          </li>
          <li>
            Optionale LexOffice anbindung
          </li>
        </ul>
        <p>
          Die durch Google Cookie erzeugte Informationen über deine Benutzung unserer Website werden in der Regel an
          einen Server von Google in den USA übertragen und dort gespeichert. Du kannst die Speicherung der Cookies
          durch eine entsprechende Einstellung deiner Browser-Software verhindern. Informationen des Drittanbieters:
          Google Dublin, Google Ireland Ltd., Gordon House, Barrow Street, Dublin 4, Ireland, Datenschutzerklärung:
          http://www.google.de/intl/de/policies/privacy Darüber hinaus geben wir Ihre personenbezogenen Daten nur an
          Dritte weiter, wenn Sie nach Art. 6 Abs. 1 S. 1 lit. a DSGVO eine ausdrückliche Einwilligung dazu erteilt
          haben.
        </p>
      </li>
      <li>
        <h3>Einsatz von Cookies, Plugins und sonstige Dienste auf unserer Webseite</h3>
        <h4>
          a) Cookies
        </h4>
        <p>
          Auf unseren Webseiten nutzen wir Cookies. Bei Cookies handelt es sich um kleine Textdateien, die auf Ihrer
          Festplatte dem von Ihnen verwendeten Browser durch eine charakteristische Zeichenfolge zugeordnet und
          gespeichert werden und durch welche der Stelle, die das Cookie setzt, bestimmte Informationen zufließen.
          Cookies können keine Programme ausführen oder Viren auf Ihren Computer übertragen und daher keine Schäden
          anrichten. Sie dienen dazu, das Internetangebot insgesamt nutzerfreundlicher und effektiver, also für Sie
          angenehmer zu machen. Cookies können Daten enthalten, die eine Wiedererkennung des genutzten Geräts möglich
          machen. Teilweise enthalten Cookies aber auch lediglich Informationen zu bestimmten Einstellungen, die nicht
          personenbeziehbar sind. Cookies können einen Nutzer aber nicht direkt identifizieren. Man unterscheidet
          zwischen Session-Cookies, die wieder gelöscht werden, sobald Sie ihren Browser schließen und permanenten
          Cookies, die über die einzelne Sitzung hinaus gespeichert werden. Hinsichtlich ihrer Funktion unterscheidet
          man bei Cookies wiederum zwischen:
        </p>
        <ul>
          <li>
            Technical Cookies: Diese sind zwingend erforderlich, um sich auf der Webseite zu bewegen, grundlegende
            Funktionen zu nutzen und die Sicherheit der Webseite zu gewährleisten; sie sammeln weder Informationen über
            Sie zu Marketingzwecken noch speichern sie, welche Webseiten Sie besucht haben;
          </li>
          <li>
            Performance Cookies: Diese sammeln Informationen darüber, wie Sie unsere Webseite nutzen, welche Seiten Sie
            besuchen und z.B. ob Fehler bei der Webseitennutzung auftreten; sie sammeln keine Informationen, die Sie
            identifizieren könnten – alle gesammelten Informationen sind anonym und werden nur verwendet, um unsere
            Webseite zu verbessern und herauszufinden, was unsere Nutzer interessiert;
          </li>
          <li>
            Advertising Cookies, Targeting Cookies: Diese dienen dazu, dem Webseitennutzer bedarfsgerechte Werbung auf
            der Webseite oder
            Angebote von Dritten anzubieten und die Effektivität dieser Angebote zu messen; Advertising und Targeting
            Cookies werden maximal 13 Monate lang gespeichert;
          </li>
          <li>
            Sharing Cookies: Diese dienen dazu, die Interaktivität unserer Webseite mit anderen Diensten (z.B. sozialen
            Netzwerken) zu verbessern; Sharing Cookies werden maximal 13 Monate lang gespeichert.
          </li>
        </ul>
        <p>
          Jeder Einsatz von Cookies, der nicht zwingend technisch erforderlich ist, stellt eine Datenverarbeitung dar,
          die nur mit einer ausdrücklichen und aktiven Einwilligung Ihrerseits gem. Art. 6 Abs. 1 S. 1 lit. a DSGVO
          erlaubt ist. Dies gilt insbesondere für die Verwendung von Advertising, Targeting oder Sharing
          Cookies.8Darüber hinaus geben wir Ihre durch Cookies verarbeiteten personenbezogenen Daten nur an Dritte
          weiter, wenn Sie nach Art. 6 Abs. 1 S. 1 lit. a DSGVO eine ausdrückliche Einwilligung dazu erteilt haben.
        </p>
        <h4>
          b) Cookie-Einstellungen
        </h4>
        <p>
          Weitere Informationen darüber, welche Cookies wir verwenden und wie Sie Ihre Cookie-Einstellungen verwalten
          und bestimmte Arten von Tracking deaktivieren können, finden Sie in unseren Cookie-Einstellungen.
        </p>
        <h4>
          c) Social Media Plugins
        </h4>
        <p>
          Auf unseren Webseiten setzen wir keine Social-Media-Plugins ein. Sofern unsere Webseiten Symbole von
          Social-Media-Anbietern enthalten, nutzen wir diese lediglich zur passiven Verlinkung auf die Seiten der
          jeweiligen Anbieter.
        </p>
      </li>
    </ol>
  </v-container>
</template>
<script>


export default {
  name: 'DataProtection',
  mounted() {
    window.scrollTo(0, 0)
  },
}
</script>
<style>
h2 {
  padding-top: 15px;
  padding-bottom: 10px
}

h3 {
  padding-top: 10px;
  padding-bottom: 7px
}
</style>
